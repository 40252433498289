import React from "react";
import './Footer.css'

const Footer = () => {
    return(
        <footer>
            <a href={"https://github.com/chasenpse/bakerscalc"}>
                <div className={"gh"} />
            </a>
        </footer>
    )
}

export default Footer;